<script lang="ts" setup>
const { data: appInitData } = useAppInitData();
const isGuest = useIsGuest();
const { t } = useT();
const { insertGA } = useGA({ autoLoad: false });
const { cookiesEnabledIds } = useCookieControl();
const { checkDeviceId } = usePopupAds();

const isCookiesNoticeEnabled = computed(() => appInitData.value?.isCookiesNoticeEnabled);

let stopWatch: () => void = () => {};

const fireAnalytics = () => {
	checkDeviceId();
	insertGA();
	stopWatch();
};

stopWatch = watchEffect(() => {
	if (cookiesEnabledIds?.value?.includes("analytics") || !isGuest.value || !isCookiesNoticeEnabled.value) {
		fireAnalytics();
	}
});

if (!isGuest.value) {
	stopWatch();
}

onUnmounted(() => {
	stopWatch();
});
</script>
<template>
	<CookieControl v-if="isGuest && isCookiesNoticeEnabled" class="cookie-wrapper">
		<template #bar>
			<AText variant="tonk">
				{{
					t(
						"This website uses cookies to enhance user experience and to analyze performance and traffic on our website.\n We also share information about your use of our site with our social media, advertising and analytics partners"
					)
				}}
			</AText>
			<br />
			<NuxtLink class="link" target="_blank" to="/page/cookie-notice">
				<AText :modifiers="['link', 'underline']">{{ t("Nolimitcoins Cookie Notice") }}</AText>
			</NuxtLink>
		</template>
	</CookieControl>
</template>

<style lang="scss">
.cookieControl {
	.cookieControl__Bar {
		background: var(--neutral-15);
		width: 90%;
		display: flex;
		justify-self: center;
		border-radius: 16px;
		padding: 16px 32px;
		margin-left: auto;
		margin-right: auto;
		font-family: "Readex Pro", sans-serif;
		bottom: 15px;

		@include media-breakpoint-down(md) {
			padding: 16px;
			bottom: 90px;
		}
	}

	.cookieControl__BarContainer {
		padding: 0;
		gap: 16px;
		align-items: center;

		@include media-breakpoint-down(md) {
			gap: 0;
		}
	}

	.cookieControl__ControlButton {
		display: none;
	}

	.cookieControl__BarButtons {
		button {
			width: 122px;
			height: 36px;
			border-radius: 8px;
			padding: 9px 0;
			text-align: center;
			font-weight: 700;
			font-size: 14px;
			line-height: 18px;
			text-transform: uppercase;

			&:nth-child(1) {
				background-color: var(--secondary-50);
				color: white;
			}
			&:nth-child(2) {
				color: var(--secondary-50);
				background-color: transparent;
				border: 1px solid var(--secondary-50);
			}
			&:nth-child(3) {
				display: none;
			}

			@include media-breakpoint-down(md) {
				width: 100%;
				height: 52px;
				font-size: 16px;
				line-height: 20px;

				&:nth-child(1) {
					margin-top: 0px;
				}

				&:nth-child(2) {
					margin-top: 12px;
				}
			}
		}
	}
}
</style>
